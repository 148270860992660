import React,{useLayoutEffect} from "react";
import CommonInformation from "../common/CommonInformation";
import Pricing from "../common/Pricing";
import ReservationData from "./ReservationData";

function Reservation() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
      <ReservationData />
      <CommonInformation />
      {/* <Pricing /> */}
    </div>
  );
}

export default Reservation;
