import React from "react";
import checkcricle from "../../assets/checkcricle.svg";
import { Button } from "react-bootstrap";

function ConfirmationScreen() {
  return (
    <div>
      <div className="m50">
        <div className="paymentdiv">
          <img src={checkcricle} alt="step1" className="checkimage" />
          <p className="paymenth">Thank you</p>
          <p className="paymentsh">
            you have successfully registered <br />
            your business.please login to <br />
            continue
          </p>
          <Button
            className="primarys w125"
            href="https://admin.mytablefinder.co.uk/"
          >
            {" "}
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationScreen;
