import React, { useState } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import HomeModal from "../pages/home/HomeModal";
import "./header.css";
import "../../user/common.css";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
// import homebanner from '';

function Header() {
  const [modalShow, setModalShow] = useState(false);
  const [headerToggle, setHeaderToggle] = useState(false);
  const handleClose = () => setModalShow(false);

  return (
    <>
      <Navbar collapseOnSelect expand="sm" className="header" variant="dark">
        <Link className="logo" to="/">
          <img src={logo} alt="logo" className="logoImg" />
        </Link>
        {headerToggle ? (
          <>
            <AiOutlineClose onClick={() => setHeaderToggle(false)} />
            {
              <div className="navtopdiv">
                <div className="navdiv" onClick={() => setHeaderToggle(false)}>
                  <div className="headertitle">
                    <Link to="/">Home</Link>
                  </div>
                  <div className="headertitle">Features</div>
                  <div className="subheadertitle">
                    <Link to="/adminTool">Admin Tool</Link>
                  </div>
                  <div className="subheadertitle">
                    <Link to="/waiter">Waiter App</Link>
                  </div>
                  <div className="subheadertitle">
                    <Link to="/reservation">Reservation Widget</Link>
                  </div>
                  <div className="headertitle">
                    <Link to="/price">Pricing</Link>
                  </div>
                  <div
                    className="headertitle"
                    onClick={() => setModalShow(true)}
                  >
                    Demo{" "}
                  </div>
                  <div className="headertitle">
                    <Link to="/aboutUs">About Us</Link>
                  </div>
                </div>
              </div>
            }
          </>
        ) : (
          <Navbar.Toggle
            aria-controls="navbarScroll"
            onClick={() => setHeaderToggle(true)}
          />
        )}

        <Navbar.Collapse id="navbarScroll">
          <Nav className="mr-auto"></Nav>
          <Nav className="mr-100" style={{ maxHeight: "100px" }} navbarScroll>
            <Nav.Link href="/" className="mr">
              Home
            </Nav.Link>
            <NavDropdown className="mr headerdrop" title="Features">
              <NavDropdown.Item href="/adminTool">Admin Tool</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/waiter">Waiter App</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/reservation">
                Reservation Widget
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="/price" className="mr">
              Pricing
            </Nav.Link>
            <Nav.Link className="mr" onClick={() => setModalShow(true)}>
              Demo
            </Nav.Link>
            <HomeModal show={modalShow} handleClose={handleClose} />
            <Nav.Link href="/aboutUs" className="mr">
              About Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;
