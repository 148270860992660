import { Fragment } from 'react';
import React from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import './loader.css';
import Load from './loader.gif';
const Loader = (props: any) => {

    return <Fragment>
        <div hidden={!props.visible} className="Loader-screen">
            <div className="loader-inner">

                {/* <span><ProgressSpinner/></span><span className="loadingTxt"> Loading...</span> */}

                <span ><img src={Load} alt="spinner" /></span> <p className="loadingTxt"> Loading</p>

            </div>

        </div>
    </Fragment>
}

export default Loader;