import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Modal } from "react-bootstrap";
import "../../../user/common.css";
import "./homeData.css";
import secton2 from "../../assets/Morning-essential-cuate.png";
import Attachedfilespana from "../../assets/Attachedfilespana.png";
import Spreadsheetsrafiki from "../../assets/Spreadsheets-rafiki.png";
import Devicesrafiki from "../../assets/Devicesrafiki.png";
import Coffeebreakamico from "../../assets/Coffeebreakamico.png";
import Creditcardrafiki from "../../assets/Creditcardrafiki.png";
import Emailcampaigncuate from "../../assets/Emailcampaigncuate.png";
import hometop from "../../assets/hometop.png";
import homebotom from "../../assets/homebotom.png";
import HomeModal from "./HomeModal";
function HomeData() {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);

  return (
    <div>
      <div className="section1">
        <div className="section1div">
          <div className="row banner">
            <div className="hometop">
              <img
                src={hometop}
                alt="secton2"
                className="EmailcampaigncuateImg"
              />
            </div>
            <div className="col-6 bannerleft">
              <div className="homebannerone">
                Simplify your table and floor management experience with
                MyTableFinder
              </div>
              <div className="homebannertwo">
                Presenting a super-easy and highly effective Table Management
                and
                <br />
                Booking System that notonly saves time but provides you with a
                seamless
                <br />
                experience with your customers.
              </div>
              <div className="homebannerthree">
                Enjoy our <span className="free">Free</span> Plan that includes
                up to 100 bookings per month
                <br />
                No Credit Card Required!
              </div>
              <Button className="btnsign" href="/buyPlan/free">
                Sign up free account
              </Button>
              <HomeModal show={modalShow} handleClose={handleClose} />
            </div>
          </div>
          <div className="homebottomimg"></div>
        </div>
      </div>

      <div className="rows section2">
        <div className="homecommondiv">
          <div className="text-left section2top">
            <div className="section2Right">
              <img src={secton2} alt="secton2" />
            </div>
          </div>
          <div className="">
            <div className="section2left">
              <p className="sectiontwoleft1">
                What makes MyTableFinder the perfect match for your business?
              </p>
              <p className="sectiontwoleft2">
                Managing countless bookings and reservations can be very tiring
                at times. Hence, MyTableFinder brings you various management
                models that could help you save your precious time and still top
                the levels of professionalism before your customers. Our varied
                services give you the utmost flexibility to make an informed and
                wise decision. Each service customised to meet your needs so
                that you can be in complete control of your business, which runs
                smoothly.
              </p>
              <p className="sectiontwoleft2">
                And don’t worry. We have a <b>Free plan</b> as well! Our free
                plan includes up to
                <b>100 bookings a month</b> with almost all the perks that come
                with membership.
              </p>
              <Link to="/buyPlan/free">
              <Button className="trynowbtn"> TRY NOW </Button>
              </Link>
            </div>
          </div>
          <div className="text-center section2botom">
            <div className="section2Right">
              <img src={secton2} alt="secton2" />
            </div>
          </div>
        </div>
      </div>

      <div className="section3">
        <div className="sectionbtn"></div>
        Reasons to take up <br /> MyTableFinder for your <br /> restaurant asap!
      </div>

      <div className="rows section4">
        <div className="homecommondiv">
          <div className="section4Imgdiv">
            <img
              className="section4Img"
              src={Attachedfilespana}
              alt="secton2"
            />
          </div>
          <div className="section4Data">
            <p className="section41">
              1. Gives you complete CONTROL of your table bookings, staff and
              floor management and time
            </p>
            <p className="section42">
              MyTableFinder’s management plans can give you the power to take
              full control of the working of your restaurant. With the help of
              an automatic and visual interface, you can have a clear picture of
              exactly how many tables are booked, which waiter is where and what
              to expect from your customers.
              <br />
            </p>
            <p className="section42">
              With such an easy interface, you can manage the status of the
              events with just a few clicks. This not only saves you time but
              also is monetarily beneficial.
            </p>
          </div>
        </div>
      </div>

      <div className="rows section5">
        <div className="homecommondiv">
          <div className="section5imgDiv section5top">
            <img
              src={Spreadsheetsrafiki}
              alt="secton5"
              className="section5img"
            />
          </div>

          <div className="section5left z1">
            <p className="sectiontwoleft1">
              2. Booking management was never this EASY
            </p>
            <p className="sectiontwoleft2">
              You can simply edit or change the status of booked tables by
              following some very basic steps. This saves you time and makes the
              working environment more professional and efficient.
            </p>
            <p className="sectiontwoleft2">
              {" "}
              The flexible manual settings can help you customise the system to
              fit your requirements. You can have a visual floor plan for you to
              make better decisions in terms of your services.
            </p>
          </div>

          <div className="section5imgDiv section5botom">
            <img
              src={Spreadsheetsrafiki}
              alt="secton5"
              className="section5img"
            />
          </div>
        </div>
      </div>

      <div className="rows section6">
        <div className="homecommondiv">
          <div className="section6Imgdiv">
            <img src={Devicesrafiki} className="section6Img" alt="secton2" />
          </div>
          <div className="section6Data">
            <p className="section61">
              3. Visual interface for SIMPLIFIED floor planning
            </p>
            <div className="section62">
              A visual floor plan allows you to have a sneak peek at the table
              booking for different floors. With simple drag and drop options,
              you can format the floors to suit your management needs.
            </div>
            <div className="section62">
              Simple options allow you to view the table details as well which
              makes your work a lot more effective.
            </div>
          </div>
        </div>
      </div>

      <div className="rows section7">
        <div className="homecommondiv">
          <div className="section7Right section7top">
            <img src={Coffeebreakamico} alt="secton2" />
          </div>

          <div className="section7left">
            <p className="sectiontwoleft1">
              4. Know your customers before they enter your restaurant for a
              great FIRST IMPRESSION
            </p>
            <p className="sectiontwoleft2">
              MyTableFinder makes the customers answer some simple questions
              that can help the restaurants to understand their preferences
              better. Once they book a table, they are given a listof questions
              by which the restaurant can know before what the customer is
              expecting and provide them with just the best services. This will
              make your customers have a great first impression of you, which
              will make them trust your services more.{" "}
            </p>
          </div>

          <div className="section7Right section7botom">
            <img src={Coffeebreakamico} alt="secton2" />
          </div>
        </div>
      </div>

      <div className="rows section8">
        <div className="homecommondiv">
          <div className="section8Imgdiv">
            <img src={Creditcardrafiki} alt="secton2" className="section8Img" />
          </div>
          <div className="section8Data">
            <p className="section81">
              5. Allows you to take PRE-PAYMENTS or booking fees
            </p>
            <div className="section82">
              MyTableFinder gives the restaurants freedom to receive
              pre-payments and set their booking charges when customers book a
              table at their restaurant.
              <br />
            </div>
            <div className="section82">
              This helps them maintain a proper monetary policy and avoid faulty
              reservations. In case a customer doesn’t appear on the booked
              date, this caution amount will be sent to the restaurant’s wallet,
              without any hidden commission or charges.
            </div>
          </div>
        </div>
      </div>

      <div className="sectionCenter mt50">
        <div className="sectionCenter1">
          6 . A transparent FEEDBACK environment for both restaurants and
          customers
        </div>
        <div className="sectionCenter2">
          Restaurants can ask the customers for their feedbacks on MyTableFinder
          which may help them improve their services in future. This gives a
          clear picture to the restaurant about what to inculcate to make their
          customer’s experience worthwhile. Feedbacks are essential for the
          growth of an entity. MyTableFinder will let you have the full benefit
          of this option right after the customers leaves the restaurant.
        </div>
        <div className="emailcampaigncuateImage">
          <img
            src={Emailcampaigncuate}
            alt="secton2"
            className="EmailcampaigncuateImg"
          />
        </div>
      </div>
    </div>
  );
}

export default HomeData;
