import React from "react";
import card1 from "../../assets/card1.png";
import card2 from "../../assets/card2.png";
import card3 from "../../assets/card3.png";
import MaskGroup12 from "../../assets/MaskGroup12.png";
import Group38016 from "../../assets/Group38016.png";
import productfea from "../../assets/productfea.png";
import { Card } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import "./commonInformation.css";
import {
  BiEnvelope,
  BiLaptop,
  BiTable,
  BiBookOpen,
  BiWalletAlt,
} from "react-icons/bi";
function CommonInformation() {
  return (
    <div className="m50">
      <div className="sectionCenter">
        <div className="sectionCenter1">What our customers say</div>
        <div className="sectionCenter2">
          We care for our customers and listen what they say, for us happy
          customer means more and more strong business relations and services to
          come.
        </div>
      </div>

      <div className="carddisplay">
        <div className="cardsection cardrows">
          <div className="cardsize">
            <Card className="cardbody">
              <div className="cardImgdiv">
                <img src={card1} alt="secton2" className="cardImg" />
              </div>
              <div>
                <StarRatings
                  rating={4}
                  starRatedColor="yellow"
                  starDimension="22px"
                  starSpacing="1px"
                  numberOfStars={5}
                  name="rating"
                />
              </div>
              <Card.Body>
                <Card.Text>
                  “Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.”
                </Card.Text>
              </Card.Body>
              <div className="cardfooter">
                <small className="text-muted">
                  <b>- Sam Feldt,</b> Sam’s Restaurant
                </small>
              </div>
            </Card>
          </div>
          <div className="cardsize">
            <Card className="cardbody">
              <div className="cardImgdiv">
                <img src={card2} alt="secton2" className="cardImg" />
              </div>
              <div>
                <StarRatings
                  rating={4.5}
                  starRatedColor="yellow"
                  starDimension="22px"
                  starSpacing="1px"
                  numberOfStars={5}
                  name="rating"
                />
              </div>
              <Card.Body>
                <Card.Text>
                  “Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.”
                </Card.Text>
              </Card.Body>
              <div className="cardfooter">
                <small className="text-muted">
                  <b>- Sam Feldt,</b> Sam’s Restaurant
                </small>
              </div>
            </Card>
          </div>
          <div className="cardsize">
            <Card className="cardbody">
              <div className="cardImgdiv">
                <img src={card3} alt="secton2" className="cardImg" />
              </div>
              <div>
                <StarRatings
                  rating={5}
                  starRatedColor="yellow"
                  starDimension="22px"
                  starSpacing="1px"
                  numberOfStars={5}
                  name="rating"
                />
              </div>
              <Card.Body>
                <Card.Text>
                  “Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.”
                </Card.Text>
              </Card.Body>
              <div className="cardfooter">
                <small className="text-muted">
                  <b>- Sam Feldt,</b> Sam’s Restaurant
                </small>
              </div>
            </Card>
          </div>
        </div>
      </div>

      <div className="rows sectionTrusted">
        <div className="homecommondiv">
          <div className="sectionTrusted4Data">
            <p className="sectionTrusted41">Trusted By Great Restaurants</p>
            <p className="sectionTrusted42">
              We love to see and listen from our customers and provide service,
              solving the queries and maintain the good relationship!{" "}
            </p>
          </div>
          <div className="sectionTrustedImgdiv">
            <img
              className="sectionTrustedImg"
              src={MaskGroup12}
              alt="secton2"
            />
          </div>
        </div>
      </div>

      <div className="rows productfea mt50">
        <div className="homecommondiv">
          <div className="productdiv">
            <div className="productfealeft1">Product Features</div>

            <div className="productstep">
              <div className="productfealeft2">
                <span>
                  <BiLaptop />
                </span>
                Online booking
              </div>
              <div className="productfealeft3">
                Increase conversions and optimize the table booking process by
                creating your custom 24/7 open restaurant reservation system.
              </div>
            </div>
            <div className="productstep">
              <div className="productfealeft2">
                <span>
                  <BiBookOpen />
                </span>
                Reservation management
              </div>
              <div className="productfealeft3">
                The built-in reservation management system allows admins and
                waiters to add, edit and delete reservations, manage client data
                and availability.
              </div>
            </div>
            <div className="productstep">
              <div className="productfealeft2">
                <span>
                  <BiEnvelope />
                </span>
                Email & SMS notifications
              </div>
              <div className="productfealeft3">
                The table reservation system can be set to remind your clients
                for upcoming reservations via email or short text messages.
              </div>
            </div>
            <div className="productstep">
              <div className="productfealeft2">
                <span>
                  <BiTable />
                </span>
                Table management
              </div>
              <div className="productfealeft3">
                Upload a restaurant floor map into your table reservation system
                and enable clients to select and book the desired table(s)
                online.
              </div>
            </div>
            <div className="productstep">
              <div className="productfealeft2">
                <span>
                  <BiWalletAlt />
                </span>
                Advance Reservation Deposits
              </div>
              <div className="productfealeft3">
                Set your hotel customer threshold, apply advance deposits and
                avoid losses from customer’s reservation cancellation as a last
                resort.
              </div>
            </div>
          </div>
          <div className="productfeaimgdiv">
            <img className="productfeaImg" src={productfea} alt="productfea" />
          </div>
        </div>
      </div>

      <div className="sectionCenter mt100">
        <div className="sectionCenter1">
          Reservation, Admin & Waiter PWA software for the future of hospitality
        </div>
        <div className="sectionCenter2">
          Welcome guests back, control guest flow, fill more tables and easily
          deliver hospitality that builds loyalty. Bring all your reservations
          and guest data into one place so you can do what you do best - deliver
          outstanding guest experiences.
        </div>
        <div className="group38016">
          <img src={Group38016} alt="secton2" className="group38016Img" />
        </div>
      </div>
    </div>
  );
}

export default CommonInformation;
