import React, { useState,useEffect } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ImageUploading from "react-images-uploading";
import CONST from "../common/Const";
import upload from "../../assets/uploadicon.svg";
import closeicone from "../../assets/closeicone.svg";
import axios from "axios";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

function RestaurantDetails({ formData, handleChange, handleStatusChange }) {
  const [logoError, setLogoError] = useState(false);
  const [validated, setValidated] = useState(false);
  const [phone, setPhone] = useState(formData?.phone1);
  const [phone2, setPhone2] = useState(formData?.phone2);


  useEffect(() => {
    window.scrollTo(0, 0)
},[]);
  const onChangeImageUpload = (image) => {
    handleChange("restaurantLogo", image);
    if (!image.length > 0) return;
    var imageData = new FormData();

    imageData.append("logo_url", image[0].file);
    axios
      .post(
        `${process.env.REACT_APP_MAIN_API_BASE_URL}/image_upload/`,
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        handleChange("logoUrl", res.data);
      })
      .catch((err) => {
        toast.error(err.response?.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);
    if (!formData?.restaurantLogo?.length > 0) {
      setLogoError(true);
      return;
    }
    if (form.checkValidity() === true) {
      handleStatusChange("restaurantDetails", CONST.COMPLETED);
      handleStatusChange("addressDetails", CONST.ACTIVE);
    }
  };
  return (
    <div className="m50">
      <div className="resdetail">
        <p className="resdetailheader">
          Let’s get your restaurant set up, it only takes a minute.
         
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Label style={{color:"#787878"}}>ACCOUNT HOLDER INFORMATION</Form.Label>
        <br/>
        <Form.Row>
            <Form.Group as={Col} md={Row} controlId="RegisteredBusinessName *">
              <p className="ressubheader">First Name *</p>
              <Form.Control
                type="text"
                name="firstName"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.firstName}
                required
                maxLength="15"
              />
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="RegisteredBusinessName *">
              <p className="ressubheader">Last Name *</p>
              <Form.Control
                type="text"
                name="lastName"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.lastName}
                required
                maxLength="15"
              />
            </Form.Group>
           
          </Form.Row>
          <Form.Row>
          <Form.Group as={Col} md={Row} controlId="RestaurantEmail">
              <p className="ressubheader">Registration Email *</p>
              <Form.Control
                type="email"
                name="restaurantEmail"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.restaurantEmail}
                required
              />
              <Form.Control.Feedback type="invalid">
                Invalid Email
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} md={Row} controlId="Phone1">
              <p className="ressubheader">Phone Number *</p>

              <Form.Control
                as={PhoneInput}
                // mask="9999999999"
                // maskChar={null}
                type="text"
                inputProps={{
                  name: "phone1",
                  required: true,
                  
                }}
                onChange={(e) => {handleChange("phone1", e);setPhone(e)}}
                value={phone}
                onlyCountries={["gb", "in"]}
                country={"gb"}
              />

              {/* <div style={{ color: "red" }}>{phoneError}</div>
              <Form.Control.Feedback type="invalid">
                {phoneError}
              </Form.Control.Feedback> */}
            </Form.Group>
            
          </Form.Row>
          <br/>
          <br/>
        <Form.Label style={{color:"#787878"}}>RESTAURANT INFORMATION</Form.Label>

          <Form.Row>
            <Form.Group as={Col} md={Row} controlId="RegisteredBusinessName *">
              <p className="ressubheader">Registered Business Name *</p>
              <Form.Control
                type="text"
                name="businessName"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.businessName}
                required
                maxLength="50"
              />
            </Form.Group>

            <Form.Group as={Col} md={Row} controlId="formGridState">
              <Form.Label className="ressubheader">Time Zone</Form.Label>
              <Form.Control
                className="timezone"
                as="select"
                name="timeZone"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.timeZone}
                required
              >
                <option>Europe/London</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={Row} controlId="formGridRestaurant">
            <p className="ressubheader">Restaurant Name *</p>
              <Form.Control
                type="text"
                name="restaurantName"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.restaurantName}
                required
                style={{marginBottom:"15px"}}
                maxLength="50"
              />
              <p className="ressubheader">Restaurant Email *</p>
              <Form.Control
                type="email"
                name="businessEmail"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.businessEmail}
                required
                maxLength="50"
                style={{marginBottom:"15px"}}

              />
              <Form.Label className="persubheader">Restaurant Phone Number * </Form.Label>
              <Form.Control
                as={PhoneInput}
                // mask="9999999999"
                // maskChar={null}
                type="text"
                name="phone2"
                onChange={(e) => {handleChange("phone2", e);setPhone2(e)}}
                value={phone2}
                onlyCountries={["gb", "in"]}
                country={"gb"}
              />
              
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="formGridAddress1">
            <p className="ressubheader">Restaurant Logo *</p>
              <ImageUploading
                className="position-relative resfile form-file"
                name="restaurantLogo"
                value={formData?.restaurantLogo}
                onChange={(image) => {
                  onChangeImageUpload(image);
                }}
                dataURLKey="data_url"
                acceptType={["png"]}
                maxFileSize={40000}
                resolutionType="less"
                resolutionWidth={"512"}
                resolutionHeight={"512"}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                  errors,
                }) => (
                  // write your building UI
                  <div className="upload__image-wrapper">
                    {!formData?.restaurantLogo?.length > 0 && (
                      <div
                        style={
                          isDragging ? { color: "red" } : { color: "black" }
                        }
                        onClick={
                          formData?.restaurantLogo
                            ? () => onImageUpdate(0)
                            : onImageUpload
                        }
                        {...dragProps}
                      >
                        <div
                          className="fileavtar"
                          style={
                            logoError
                              ? { border: "1px solid red" }
                              : { border: "1px solid #000" }
                          }
                        >
                          <div className="resfileupload col-12">
                            <img
                              src={upload}
                              alt="step1"
                              className="resfileuploadimg"
                            />
                            <div className="resfileuploadtext col-12">
                              Drag and Drop Image File
                              <br />
                              Supported Format .png
                              <br/>
                              <br/>
                              Recommended size 200x200
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {errors && (
                      <div>
                        {errors.acceptType && (
                          <span style={{ color: "red" }}>
                            Only PNG file allowed
                          </span>
                        )}
                        {errors.maxFileSize && (
                          <span style={{ color: "red",fontSize:"13px" }}>
                            Image size should be lessthan or equal to 40KB
                          </span>
                        )}
                        {errors.resolution && (
                          <span style={{ color: "red" }}>
                            File should be 512*512
                          </span>
                        )}
                      </div>
                    )}
                    &nbsp;
                    {imageList.map((image, index) => (
                      <div key={index} className="fileavtar">
                        <div className="closeimage"></div>
                        <div className="col-12">
                          <img
                            src={image.data_url}
                            alt=""
                            className="imgfileAvtar"
                          />
                        </div>
                        <img
                          onClick={() => onImageRemove(index)}
                          src={closeicone}
                          alt="step1"
                          className="closeicone"
                        />
                      </div>
                    ))}
                  </div>
                )}
              </ImageUploading>

              <div className="mt25">
                <Button
                  className="primarys"
                  type="submit"
                  style={{float:"right"}}
                  // disabled={
                  //   formData?.restaurantName && formData?.businessName
                  //     ? false
                  //     : true
                  // }
                >
                  Next
                </Button>
              </div>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}

export default RestaurantDetails;
