import React,{useLayoutEffect} from "react";
import CommonInformation from "../common/CommonInformation";
import Pricing from "../common/Pricing";
import AdminToolData from "./AdminToolData";


function AdminTool() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
      <AdminToolData />
      <CommonInformation />
      {/* <Pricing /> */}
    </div>
  );
}

export default AdminTool;
