import React, { useState, useEffect ,useLayoutEffect} from "react";
import AddressDetails from "./AddressDetails";
import ConfirmationScreen from "./ConfirmationScreen";
import PaymentDetails from "./PaymentDetails";
import PersonalInformation from "./PersonalInformation";
import RestaurantDetails from "./RestaurantDetails";
import { Link } from "react-router-dom";
import CONST from "../common/Const";
import step1 from "../../assets/step1.svg";
import step2 from "../../assets/step2.svg";
import step3 from "../../assets/step3.svg";
import step4 from "../../assets/step4.svg";
import step5 from "../../assets/step5.svg";
import step1t from "../../assets/step1t.svg";
import step2t from "../../assets/step2t.svg";
import step3t from "../../assets/step3t.svg";
import step4t from "../../assets/step4t.svg";
import step5t from "../../assets/step5t.svg";
import line from "../../assets/line.svg";
import checkcricle from "../../assets/checkcricle.svg";
import greenline from "../../assets/greenline.svg";

import "./buyplan.css";
function BuyPlan({ match }) {

  useEffect(() => {
    window.scrollTo(0, 0)
},[]);

  const [type, setType] = useState(match.params?.type);
  const [status, setStatus] = useState({
    restaurantDetails: CONST.ACTIVE,
    personalInformation: CONST.DISABLE,
    addressDetails: CONST.DISABLE,
    paymentDetails: CONST.DISABLE,
    confirmationScreen: CONST.DISABLE,
  });
  const [formData, setFormData] = useState({
    timeZone: "Europe/London",
  });
  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  const handleStatusChange = (name, value) => {
    setStatus((prev) => ({ ...prev, [name]: value }));
  };
  console.log(`formData`, formData);
  console.log(`status`, status);
  useEffect(() => {
    if (type == "success" || type == "fail") {
      setStatus((prev) => ({
        ...prev,
        restaurantDetails: CONST.COMPLETED,
        personalInformation: CONST.COMPLETED,
        addressDetails: CONST.COMPLETED,
        paymentDetails: CONST.ACTIVE,
      }));
    }
  }, [type]);
  return (
    <div className="">
      <div className="subheader">
        <Link to="/">Home</Link> / <span className="text">Pricing</span>
      </div>

      <div className="steper">
        <div className="step1">
          <div>
            <div className="stepimagediv">
              <img
                src={status.restaurantDetails == CONST.ACTIVE ? step1t : step1}
                alt="step1"
                className="cardImg"
              />
              <div
                className={
                  status.restaurantDetails == CONST.COMPLETED
                    ? "checkimage"
                    : ""
                }
              ></div>
              {/* <img
              src={status.restaurantDetails ==CONST.COMPLETED ? checkcricle : ''}
              alt="step1"
              className="checkimage"
            /> */}
            </div>
            <p className="steptitle">
              Basic
               Details
            </p>
          </div>
          <div className="mt50">
            <img
              src={
                status.restaurantDetails == CONST.COMPLETED ? greenline : line
              }
              alt="step1"
              className="stepline"
            />
          </div>
        </div>
        {/* <div className="step1">
          <div>
            <div className="stepimagediv">
              <img
                src={
                  status.personalInformation == CONST.ACTIVE ? step2t : step2
                }
                alt="step1"
                className="cardImg"
              />
              <div
                className={
                  status.personalInformation == CONST.COMPLETED
                    ? "checkimage"
                    : ""
                }
              ></div>

              
            </div>

            <p className="steptitle">
              Personal <br /> Information
            </p>
          </div>
          <div className="mt50">
            <img
              src={
                status.personalInformation == CONST.COMPLETED ? greenline : line
              }
              alt="step1"
              className="stepline"
            />
          </div>
        </div> */}
        <div className="step1">
          <div>
            <div className="stepimagediv">
              <img
                src={status.addressDetails == CONST.ACTIVE ? step3t : step3}
                alt="step1"
                className="cardImg"
              />
              <div
                className={
                  status.addressDetails == CONST.COMPLETED ? "checkimage" : ""
                }
              ></div>

              {/* <img
              src={status.addressDetails ==CONST.COMPLETED ? checkcricle : ''}
              alt="step1"
              className="checkimage"
            /> */}
            </div>
            <p className="steptitle">
              Address Details
            </p>
          </div>
          <div className="mt50">
            <img
              src={status.addressDetails == CONST.COMPLETED ? greenline : line}
              alt="step1"
              className="stepline"
            />
          </div>
        </div>
        {!(type == "free") && (
          <div className="step1">
            <div>
              <div className="stepimagediv">
                <img
                  src={status.paymentDetails == CONST.ACTIVE ? step4t : step4}
                  alt="step1"
                  className="cardImg"
                />
                <div
                  className={
                    status.paymentDetails == CONST.COMPLETED ? "checkimage" : ""
                  }
                ></div>

                {/* <img
              src={status.paymentDetails ==CONST.COMPLETED ? checkcricle : ''}
              alt="step1"
              className="checkimage"
            /> */}
              </div>
              <p className="steptitle">
                Payment
                Details
              </p>
            </div>
            <div className="mt50">
              <img
                src={
                  status.paymentDetails == CONST.COMPLETED ? greenline : line
                }
                alt="step1"
                className="stepline"
              />
            </div>
          </div>
        )}

        <div className="step1">
          <div>
            <div className="stepimagediv">
              <img
                src={status.confirmationScreen == CONST.ACTIVE ? step5t : step5}
                alt="step1"
                className="cardImg"
              />
              <div
                className={
                  status.confirmationScreen == CONST.COMPLETED
                    ? "checkimage"
                    : ""
                }
              ></div>

              {/* <img
              src={status.confirmationScreen ==CONST.COMPLETED ? checkcricle : ''}
              alt="step1"
              className="checkimage"
            /> */}
            </div>
            <p className="steptitle">
              Confirmation Screen
            </p>
          </div>
        </div>
      </div>
      {/* image code */}

      {/* restaurantDetails */}
      {status.restaurantDetails == CONST.ACTIVE && (
        <RestaurantDetails
          handleChange={handleChange}
          formData={formData}
          handleStatusChange={handleStatusChange}
        />
      )}

      {/* personal PersonalInformation */}
      {status.personalInformation == CONST.ACTIVE && (
        <PersonalInformation
          handleChange={handleChange}
          formData={formData}
          handleStatusChange={handleStatusChange}
        />
      )}

      {/* AddressDetails */}
      {status.addressDetails == CONST.ACTIVE && (
        <AddressDetails
          handleChange={handleChange}
          formData={formData}
          handleStatusChange={handleStatusChange}
          type={type}
        />
      )}

      {/* payment Details */}
      {status.paymentDetails == CONST.ACTIVE && (
        <PaymentDetails
          handleChange={handleChange}
          formData={formData}
          handleStatusChange={handleStatusChange}
          type={type}
        />
      )}

      {/* ConfirmationScreen */}
      {status.confirmationScreen == CONST.COMPLETED && (
        <ConfirmationScreen
          handleChange={handleChange}
          formData={formData}
          handleStatusChange={handleStatusChange}
        />
      )}
    </div>
  );
}

export default BuyPlan;
