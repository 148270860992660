import React from "react";
import "./adminTool.css";
import Group37908 from '../../assets/Group37908.png';
import Component12_1 from '../../assets/Component12_1.png';
import Component12_2 from '../../assets/Component12_2.png';
import Group38186 from '../../assets/Group38186.png';
import Group38188 from '../../assets/Group38188.png';
import Group38015 from '../../assets/Group38015.png';
import Landingpagebro from '../../assets/Landingpagebro.png';
import Group381881 from '../../assets/Group381881.png';
import adminsection5top from '../../assets/adminsection5top.png';
import Group38008 from '../../assets/Group38008.png';
import profimg from '../../assets/prof-img.png';
import customerverification from '../../assets/customer-verification.svg';

import { Link } from "react-router-dom";

import { Button } from "react-bootstrap";




function AdminToolData() {
  return <>
  <div className="m50">


  <div className="adminsection1">  
      <div className="adminsection1btn"></div>
        <div className="mt25">
          Get it done with <br/>Admin Tool!  
        </div>
        <div className="Group37908Img">
          <img  src={profimg} alt='secton2' className="Group37908" />
        </div>
          <div className="mt25">
          Managing Reservations & Waiter Gets Easier 
          </div>
        <div className="adminsection1f16 mt10">
        Check out our new Admin Tool, which allows an admin to manage waiter
        activities, restaurant activities, along with day to day engagements with 
        waiter and customer and from websites as well.
        </div>
        <Link to="/buyPlan/free">

      <Button className="primarys mt25 btbp"> TRY NOW </Button></Link>
  </div>


  <div className="rows adminsection2">
  <div className="homecommondiv">
  <div className="adminsectionright adminsection2top">
        <div>
        <img className="adminmanagersectionimg top12" src={Component12_2} alt='secton2' />
        </div>
        <div>
        <img className="adminmanagersectionimg bottom12" src={Component12_1} alt='secton2' />
        </div>
      </div>  

    <div className="adminsectionleft">
        <p className="sectiontwoleft1">Manage your Waiters<br /> and Co-Admins!</p>
        <p className="sectiontwoleft2">
        This feature allows you to create, edit or delete you waiters and
        co-admin as per your needs, hence makes easy for you to work
        with right person fit for the shift concern!
        </p>
    </div>  
      <div className="adminsectionright adminsection2botom">
        <div>
        <img className="adminmanagersectionimg top12" src={Component12_2} alt='secton2' />
        </div>
        <div>
        <img className="adminmanagersectionimg bottom12" src={Component12_1} alt='secton2' />
        </div>
      </div>  
    </div>
  </div>


 <div className="rows adminsection3">  
    <div className="adminsection3ImgDiv">
      <img  src={Group381881} alt='secton2'  className="adminsection3Imgsmall"  />
      <img  src={Group38008} alt='secton2'  className="adminsection3Img"  />
    </div>
      <div className="adminsection3text">
        <p className="adminsection3div">
        Keep a record of your Bookings,<br />Cancelled Bookings,Walk-ins and<br /> much more..
        </p>  
        <p className="adminsection3p">
        Keep your data on your fingertips! Our new Dashborad start feature allows<br />
        you to keep a recode of bookings,cancelled bookings,waklins and more <br /> options on a single click!
        </p>
      </div>     
  </div> 

  <div className="rows adminsection4">
    <div className="homecommondiv">
    <div className="adminsection4right adminsection4top">
          <div className="Group38186div">
          <img className="Group38186" src={Group38186} alt='secton2' />
          </div>
      </div>  
      <div className="adminsection4left">
            <div className="group38188">
            <img className="" src={Group38188} alt='secton2' />
            </div>
            <div className="floormanger">
              <p className="sectiontwoleft1">Floor Management made easier!</p>
              <p className="sectiontwoleft2">
              Our new drag and drop feature allows to manage floor plans,
              simply by creating the floor plan drag it to the floor managed.
              </p>
            </div>  
      </div>  
      <div className="adminsection4right adminsection4botom">
          <div className="Group38186div">
          <img className="Group38186" src={Group38186} alt='secton2' />
          </div>
      </div>  
    </div>
  </div>

  <div className="rows adminsection5">
    <div className="homecommondiv">
        <div className="adminsection5left adminsection5top">
            <div className="row adminsection5topimgdiv">            
                 <img className="adminsection5topimg" src={adminsection5top} alt='secton2' />
            </div>           
        </div>  
       <div className="adminsection5right">          
              <div className="adminsectiondiv">
                <p className="sectiontwoleft1">
                  Various functionalities to keep you updated with day to day business!</p>
                <p className="sectiontwoleft2">
                This feature allows you to create, edit or delete you waiters and co-admin as per your needs,
                hence makes easy for you to work with right person fit for the shift concern!
                </p>
                <Link to="/buyPlan/free">
              <Button className="primarys mt10 btbp"> TRY NOW </Button></Link>
              </div>
              <div className="">
              <img className="Landingpagebro" src={Landingpagebro} alt='secton2' />
              </div>
        </div>  
        <div className="adminsection5left adminsection5botom">
            <div className="group38015Div">
            <img className="group38015" src={customerverification} alt='secton2' />
            </div>
        </div>  
    </div> 
  </div>  
</div>
  </>;
}

export default AdminToolData;
