import { Button, Modal, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import checkcricle from "../../assets/checkcricle.svg";
import closecircle from "../../assets/closecircle.svg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import moment from "moment";
import InputMask from "react-input-mask";

toast.configure();

function HomeModal(props) {
  const [value, onChange] = useState(new Date());
  const [validated, setValidated] = useState(false);
  const [step, setStep] = useState(1);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({});

  const handleChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };
  let timeSlots = [];
  let maxDate;

  const addMonths = (date, months) => {
    var d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };
  maxDate = addMonths(new Date(), 1);
  var x = {
    nextSlot: 30,
    startTime: "08:00 AM",
    endTime: "05:00 PM",
  };
  let slotTime = moment(x.startTime, "hh:mm A");
  var endTime = moment(x.endTime, "hh:mm A");

  while (slotTime <= endTime) {
    timeSlots.push({
      time: slotTime.format("hh:mm A"),
      fullTime: slotTime.format("HH:mm:ss"),
    });
    slotTime = slotTime.add(x.nextSlot, "minutes");
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if (form.checkValidity() === true) {
      const { firstName, lastName, phone, rbn, rn, time, restaurantEmail } =
        formData;
      const payLoad = {
        first_name: firstName,
        last_name: lastName,
        email: restaurantEmail,
        mobile: phone,
        registered_business_name: rbn,
        restaurant_name: rn,
        demo_date: moment(value).format("YYYY-MM-DD"),
        demo_time: time,
      };

      axios
        .post(
          `${process.env.REACT_APP_MAIN_API_BASE_URL}/demo_request/create`,
          payLoad
        )
        .then((res) => {
          console.log(`res`, res);
          setStep(3);
        })
        .catch((err) => {
          toast.error(err.response?.data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          //remove this code
        });
    }
  };
  console.log(`formData`, formData);

  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={true}
        style
      >
        <Modal.Body className="cal">
          {step == 1 ? (
            <div>
              <div className="row">
                <div className="ModalTitle">Book your demo request</div>
                <div className="modalcloseicon">
                  <img
                    src={closecircle}
                    alt="step1"
                    className="closecircle"
                    onClick={props.handleClose}
                  />
                </div>
              </div>
              <div className="rows mtb20">
                <Col md={6} sm={12} xs={12}>
                  <p className="calendertitle">Select Date</p>
                  <div className="">
                    <Calendar
                      onChange={onChange}
                      value={value}
                      maxDate={maxDate}
                      minDate={new Date()}
                      tileDisabled={({ date }) => !date.getDay("Sunday")}
                    />
                  </div>
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <p className="calendertitle">Select Available Time</p>
                  <div className="uptimingblock">
                    <div className="timingblock">
                      {timeSlots.map((timeSlot) => {
                        return moment(value).isBefore(moment()) &&
                          moment(timeSlot.time, "hh:mm A").isBefore(
                            moment()
                          ) ? null : (
                          <div
                            className={
                              timeSlot.fullTime == formData?.time
                                ? " timing timingClick"
                                : "timing"
                            }
                            onClick={() =>
                              handleChange("time", timeSlot.fullTime)
                            }
                          >
                            {timeSlot.time}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Col>
              </div>
              <div className="calbtn">
                <Button
                  className="float-right mr-4 mt-3 w125 primarys"
                  onClick={() => setStep(2)}
                  disabled={formData?.time ? false : true}
                >
                  CONTINUE
                </Button>
              </div>
            </div>
          ) : step == 2 ? (
            <div>
              <div className="row">
                <div className="ModalTitle">
                  Enter your details to confirm demo request
                </div>
                <div className="modalcloseicon">
                  <img
                    src={closecircle}
                    alt="step1"
                    className="closecircle"
                    onClick={props.handleClose}
                  />
                </div>
              </div>
              <div className="row mtb20">
                <Form
                  className="frommodal"
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                >
                  <Form.Row>
                    <Form.Group
                      as={Col}
                      md={Row}
                      controlId="RegisteredBusinessName *"
                    >
                      <p className="persubheader">First Name *</p>
                      <Form.Control
                        type="text"
                        name="firstName"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={formData?.firstName}
                        required
                        maxLength="15"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md={Row}
                      controlId="RegisteredBusinessName *"
                    >
                      <p className="persubheader">Last Name *</p>
                      <Form.Control
                        type="text"
                        name="lastName"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={formData?.lastName}
                        required
                        maxLength="15"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={Row} controlId="Phone">
                      <p className="persubheader">Phone *</p>
                      <Form.Control
                        as={InputMask}
                        mask="+999999999999"
                        maskChar={null}
                        type="text"
                        name="phone"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={formData?.phone}
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="Registered Business Name * *"
                    >
                      <p className="persubheader">Registered Business Name *</p>
                      <Form.Control
                        type="text"
                        name="rbn"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={formData?.rbn}
                        required
                        maxLength="50"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={Row} controlId="Restaurant Name *">
                      <p className="persubheader">Restaurant Name *</p>
                      <Form.Control
                        type="text"
                        name="rn"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={formData?.rn}
                        required
                        maxLength="50"
                      />
                    </Form.Group>
                    <Form.Group as={Col} md={Row} controlId="RestaurantEmail">
                      <p className="persubheader">Restaurant Email *</p>
                      <Form.Control
                        type="email"
                        name="restaurantEmail"
                        onChange={(e) =>
                          handleChange(e.target.name, e.target.value)
                        }
                        value={formData?.restaurantEmail}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid Email
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form.Row>
                  <div className="calbtntwo">
                    <div className="row">
                      <Button
                        className="seconders w160"
                        onClick={() => setStep(1)}
                      >
                        BACK
                      </Button>
                    </div>
                    <div >
                      <Button type="submit" className="ml10 w160 primarys">
                        BOOK SESSION
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <div>
              <div className="m50">
                <div className="paymentdiv">
                  <img
                    src={checkcricle}
                    alt="step1"
                    className="checkimage"
                    onClick={props.handleClose}
                  />
                  <p className="paymenth">Demo Requested</p>
                  <p className="paymentsh">
                    You have booked your session on <br />
                    {moment(value).format("Do MMMM YYYY")},{formData?.time}
                  </p>
                  {/* <div className="demoreqdiv">
                    The demo meeting link will be sent to your registered
                    <br />
                    email address
                  </div> */}
                  <Button
                    className="w125 primarys"
                    onClick={() => {
                      setFormData({});
                      setStep(1);
                      props.handleClose();
                    }}
                  >
                    {" "}
                    Done{" "}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
export default HomeModal;
