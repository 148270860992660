import React, { Fragment, useState } from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Home from "./user/pages/home/Home";
import AdminTool from "./user/pages/adminTool/AdminTool";
import Waiter from "./user/pages/waiter/Waiter";
import Reservation from "./user/pages/reservation/Reservation";
import BuyPlan from "./user/pages/buyPlan/BuyPlan";
import AboutUS from "./user/pages/aboutus/AboutUS";
import Price from "./user/pages/common/Pricing";
import Footer from "./user/layout/Footer";
import Header from "./user/layout/Header";

function App() {
  // const [adminLogin, setAdminLogin] = useState(false);
  console.log("Env data : ", process.env.REACT_APP_STRIPE_KEY, process.env.REACT_APP_MAIN_API_BASE_URL);
  console.log()
  return (
    <Fragment>
      <Header />
      <Switch>
        {/* UI routes */}
        <Route exact path="/" component={Home} />
        <Route exact path="/price" component={Price} />
        <Route exact path="/aboutUs" component={AboutUS} />
        <Route exact path="/adminTool" component={AdminTool} />
        <Route exact path="/waiter" component={Waiter} />
        <Route exact path="/reservation" component={Reservation} />
        <Route exact path="/buyPlan/:type" component={BuyPlan} />
      </Switch>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Okay!!"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.{" "}
        {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
      </CookieConsent>
    </Fragment>
  );
}

export default App;
