import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import "../../user/common.css";
import logo from "../assets/logo.svg";
import f from "../assets/icons8-facebook-100.png";
import i from "../assets/icons8-instagram-100.png";
import t from "../assets/icons8-twitter-circled-100.png";
import { Col } from "react-bootstrap";

function Footer() {
  return (
    <>
      <div className="rows footerdiv">
        <div className="footer">
          <Col md={6} sm={6} xs={12}>
            <div className="ff">
              We care about our customers
              <br /> and services.
            </div>
            <div className="rows f16-font footerlink">
              <Link className="mr10" to="/aboutUs">
                About Us
              </Link>
              <Link className="mr10" to="">
                Privacy Policy
              </Link>
              <Link className="mr10" to="">
                Terms & Condition
              </Link>
              <Link className="mr10" to="">
                Help
              </Link>
              <Link className="mr10" to="">
                FAQs
              </Link>
            </div>
            <Link className="finder" to="/">
              <img className="logoImgFooter" src={logo} alt="MyTableFinder" />
            </Link>
            <div className="copyright">2021 All Rights Reserved</div>
          </Col>

          <Col md={3} sm={3} xs={12} className="fsercond">
            <span className="font20 op93">Registered Office</span>
            <br />
            <p className="font16 op">
              VenturesSky Ltd T/A MyTableFinder,
              <br />
              2 Square Street, Bradford <br />
              England,
              <br />
              BD4 7NP
              <br />
              Company No: 05525840
              <br />
              VAT No: 911173753
            </p>
            {/* <div className="footerAddress">
              <span className="font20 op93">Contact</span>
              <br />
              
            </div> */}
          </Col>
          <Col md={3} sm={3} xs={12} className="fsercond">
            <span className="font20 op93">Address</span>
            <br />
            <p className="font16 op">
              Harrogate Business Centre
              <br />
              Hookstone Avenue
              <br />
              Harrogate
              <br />
              North Yorkshire
              <br />
              HG2 8ER
              <br />
              United Kingdom
              <br />
            </p>
            <span className="font20 op93">Contact</span>
            <br />
            <p className="font16 op">
              08000862252
              <br />
              info@etakeawaymax.co.uk <br />
            </p>
            <span className="font20 op93">Follow Us:</span>
            <br />
            <div>
              <img className="social" src={f} alt="f" />
              <img className="social" src={i} alt="i" />
              <img className="social" src={t} alt="t" />
            </div>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Footer;
