import React, { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import CONST from "../common/Const";
import InputMask from "react-input-mask";
import axios from "axios";
import checkcricle from "../../assets/checkcricle.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PersonalInformation({ formData, handleChange, handleStatusChange }) {
  const [modalShow, setModalShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [otpVerify, setOtpVerify] = useState(false);
  const [otp, setOtp] = useState();
  const [phone, setPhone] = useState(formData?.phone1);
  const [phone2, setPhone2] = useState(formData?.phone2);
  console.log(`object`, phone, phone2);
  const onClickBack = () => {
    handleStatusChange("restaurantDetails", CONST.ACTIVE);
    handleStatusChange("personalInformation", CONST.DISABLE);
  };
  // const onClickContinue = () => {
  //   handleStatusChange("addressDetails", CONST.ACTIVE);
  //   handleStatusChange("personalInformation", CONST.COMPLETED);
  // };
  // const onClickVerify = () => {
  //   axios
  //     .post(`${process.env.REACT_APP_MAIN_API_BASE_URL}/otp/validate`, null, {
  //       params: {
  //         mobile: formData.phone1,
  //         otp: otp,
  //       },
  //     })
  //     .then((res) => {
  //       setOtpVerify(true);
  //     })
  //     .catch((err) => {
  //       setOtpError(err.response?.data.detail);
  //       setOtpVerify(true);
  //     });
  // };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    if (form.checkValidity() === true) {
      handleChange("phone1", phone);
      handleChange("phone2", phone2);
      handleStatusChange("addressDetails", CONST.ACTIVE);
      handleStatusChange("personalInformation", CONST.COMPLETED);
      // axios
      //   .post(`${process.env.REACT_APP_MAIN_API_BASE_URL}/otp/sendotp`, null, {
      //     params: {
      //       mobile_number: formData.phone1,
      //     },
      //   })
      //   .then((res) => {
      //     setModalShow(true);
      //     console.log(`res`, res);
      //   })
      //   .catch((err) => {
      //     setModalShow(true);

      //     setPhoneError(err.response?.data.detail);
      //     // this.setState({ phoneError: err.response.data.detail });
      //   });
    }
  };
  return (
    <div className="m50">
      <div className="perdetail">
        <p className="perdetailheader">
          Let us us know more about you,
          <br />
          <span className="perdetaisub">
            please enter your primary contact information, incase if we want to
            get in touch with.
          </span>
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md={Row} controlId="RegisteredBusinessName *">
              <p className="persubheader">First Name *</p>
              <Form.Control
                type="text"
                name="firstName"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.firstName}
                required
                maxLength="15"
              />
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="RegisteredBusinessName *">
              <p className="persubheader">Last Name *</p>
              <Form.Control
                type="text"
                name="lastName"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.lastName}
                required
                maxLength="15"
              />
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="RestaurantEmail">
              <p className="persubheader">Email *</p>
              <Form.Control
                type="email"
                name="restaurantEmail"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.restaurantEmail}
                required
              />
              <Form.Control.Feedback type="invalid">
                Invalid Email
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={Row} controlId="Phone1">
              <Form.Label className="persubheader">Phone 1 *</Form.Label>

              <Form.Control
                as={PhoneInput}
                // mask="9999999999"
                // maskChar={null}
                type="text"
                inputProps={{
                  name: "phone1",
                  required: true,
                  autoFocus: true,
                }}
                onChange={setPhone}
                value={phone}
                onlyCountries={["gb", "in"]}
                country={"gb"}
              />

              <div style={{ color: "red" }}>{phoneError}</div>
              <Form.Control.Feedback type="invalid">
                {phoneError}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="Phone2">
              <Form.Label className="persubheader">Phone 2 </Form.Label>
              <Form.Control
                as={PhoneInput}
                // mask="9999999999"
                // maskChar={null}
                type="text"
                name="phone2"
                onChange={setPhone2}
                value={phone2}
                onlyCountries={["gb", "in"]}
                country={"gb"}
              />
            </Form.Group>
            <Form.Group as={Col} md={Row}></Form.Group>
          </Form.Row>
          <Form.Row className="ml1">
            <div className="">
              <Button className="seconders w125" onClick={onClickBack}>
                {" "}
                Back
              </Button>
            </div>
            <div>
              <Button className="primarys ml10 w125" type="submit">
                Next
              </Button>
            </div>
          </Form.Row>
        </Form>
      </div>
      {/* <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={true}
        style
      >
        <Modal.Body>
          {!otpVerify ? (
            <>
              <div className="modaldiv">
                <img src={checkcricle} alt="step1" className="checkimage" />
                <p className="modalh">Enter OTP</p>
                <p className="modalsh">
                  Please enter OTP sent on registerd moblie number
                </p>
                <Form.Control
                  type="text"
                  name="otp"
                  onChange={(e) => setOtp(e.target.value)}
                  value={otp}
                />
                <p style={{ color: "red" }}>{otpError}</p>
                <Button
                  className="primarys ml10 w125"
                  onClick={onClickVerify}
                  disabled={otp ? false : true}
                >
                  Verify
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="modaldiv">
                <img src={checkcricle} alt="step1" className="checkimage" />
                <p className="modalh">Phone verified</p>
                <p className="modalsh">
                  It’s our pleasure to offer you
                  <br />
                  our product
                </p>
                <Button
                  className="primarys ml10 w125"
                  onClick={onClickContinue}
                >
                  CONTINUE
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal> */}
    </div>
  );
}

export default PersonalInformation;
