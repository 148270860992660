import React from "react";
import checkcricle from "../../assets/checkcricle.svg";
import closecircle from "../../assets/closecircle.svg";
import { Button } from "react-bootstrap";
import HandleStripePayment from "./StripeCheckout";
import CONST from "../common/Const.js";
function PaymentDetails({ handleStatusChange, type }) {
  // const onClickContinue = () => {
  //   handleStatusChange("paymentDetails", CONST.COMPLETED);
  //   handleStatusChange("confirmationScreen", CONST.COMPLETED);
  // };
  return (
    <div className="m50">
      {type === "success" ? (
        <>
          {
            (handleStatusChange("paymentDetails", CONST.COMPLETED),
            handleStatusChange("confirmationScreen", CONST.COMPLETED))
          }
        </>
      ) : (
        // <div className="paymentdiv">
        //   <img src={checkcricle} alt="step1" className="checkimage" />
        //   <p className="paymenth">Payment Success</p>
        //   <p className="paymentsh">
        //     It's your pleasure to offer you <br />
        //     our product
        //   </p>
        //   <Button className="primarys w125" onClick={}>
        //     {" "}
        //     CONTINUE
        //   </Button>
        // </div>
        <div className="paymentdiv">
          <img src={closecircle} alt="step1" className="checkimage" />
          <p className="paymenth">Payment Failed!</p>
          <p className="paymentsh" style={{maxWidth:"400px",margin:"0 auto 20px",lineHeight:"20px"}}>
          No Worries!, Your account has been successfully setup with Free Plan and sent an email with login credentials. Login into your account and upgrade to Unlimited Plan any time.
          </p>
          <Button
            className="primarys w125"
            href="https://admin.mytablefinder.co.uk/"
          >
            {" "}
            Login
          </Button>
        </div>
      )}
    </div>
  );
}

export default PaymentDetails;
