import React, { useState,useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import CONST from "../common/Const";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HandleStripePayment from "./StripeCheckout";
import Loader from '../Loader/loader'
toast.configure();
function AddressDetails({ formData, handleChange, handleStatusChange, type }) {
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
},[]);

  let freePlan = JSON.parse(sessionStorage.getItem('planDetails'));
  console.log("free plan : ",freePlan)
  const onClickBack = () => {
    handleStatusChange("restaurantDetails", CONST.ACTIVE);
    handleStatusChange("addressDetails", CONST.DISABLE);
  };
  const handleSubmit = (event) => {
    setLoader(true)

    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      const {
        businessName,
        timeZone,
        restaurantName,
        logoUrl,
        firstName,
        lastName,
        restaurantEmail,
        businessEmail,
        phone1,
        phone2,
        addressLine1,
        addressLine2,
        city,
        zipCode,
      } = formData;
      const payLoad = {
        org_name: businessName,
        free_plan:type === "free"?freePlan?.freePlan?.priceId:"",
        address: {
          address1: addressLine1,
          address2: addressLine2,
          land_mark: "",
          zip_code: zipCode,
          latitude: "",
          longitude: "",
          city: city,
          state: "",
          country: "",
        },
        admin: {
          first_name: firstName,
          last_name: lastName,
          email: restaurantEmail,
          mobile: phone1,
          gender: "",
        },
        restaurant: {
          name: restaurantName,
          logo_url: logoUrl,
          email: businessEmail,
          mobile:phone2,
          timezone: timeZone,
          STRIPE_SECRET_KEY: "",
        },
      };
      console.log("payload : ",payLoad)
      axios
        .post(
          `${process.env.REACT_APP_MAIN_API_BASE_URL}/organization/create_org`,
          payLoad
        )
        .then((res) => {
          console.log(`res`, res);
          setLoader(false)

          //if paid then call stripe
          if (res.status == 200) {
            if (type == "free") {
              handleStatusChange("addressDetails", CONST.COMPLETED);
              handleStatusChange("confirmationScreen", CONST.COMPLETED);
            } else {
              sessionStorage.setItem("planType", type);
              sessionStorage.setItem("customerEmail", formData.restaurantEmail);
              HandleStripePayment();
            }
          }
        })
        .catch((err) => {
          console.log(`err`, err.response);
          toast.error(err.response?.data.detail, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setLoader(false)

        });
    }
    setValidated(true);
  };
  return (
    <div className="m50">
      <Loader setLoader={setLoader} visible={loader} />
      <div className="adddetail">
        <p className="adddetailheader">
          Last step! Let us know about your restaurant location.
        </p>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} md={Row} controlId="AddressLine1">
              <p className="addsubheader">Address Line 1 *</p>
              <Form.Control
                type="text"
                name="addressLine1"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.addressLine1}
                required
                maxLength="100"
              />
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="AddressLine2">
              <p className="addsubheader">Address Line 2 *</p>
              <Form.Control
                type="text"
                name="addressLine2"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.addressLine2}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={Row} controlId="Town/City">
              <p className="addsubheader">Town/City *</p>
              <Form.Control
                type="text"
                name="city"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.city}
                required
              />
            </Form.Group>
            <Form.Group as={Col} md={Row} controlId="ZipCode">
              <p className="addsubheader">Postal Code *</p>
              <Form.Control
                type="text"
                name="zipCode"
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                value={formData?.zipCode}
                required
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="ml1">
            <div className="">
              <Button className="seconders w125" onClick={onClickBack}>
                {" "}
                Back
              </Button>
            </div>
            <div>
              <Button type="submit" className="primarys ml10 w125">
                Next
              </Button>
            </div>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}

export default AddressDetails;
