import React from "react";
import { Button } from "react-bootstrap";
import "./reservation.css";
import reservation1 from "../../assets/reservation1.png";
import reservation2 from "../../assets/reservation2.png";
import reservation3 from "../../assets/reservation3.png";
import reservation4 from "../../assets/reservation4.png";
import res1 from "../../assets/res1.png";
import resdetails from "../../assets/reservation-details.svg";
import partysize from "../../assets/party-size.svg";
import communitypana from "../../assets/Communitypana.svg";

function ReservationData() {
  return (
    <div>
      <div className="rows reservation1 mt100">
        <div className="homecommondiv">
          <div className="p50 reservationimag1div">
            <img className="reservationimag1" src={resdetails} alt="secton2" />
          </div>
          <div className="reservationimag2div mt40">
            <div className="">
              <div className="sectionbtn"></div>
              <p className="heading text-center mt10">
                Reservations are now easier!
              </p>
            </div>
            <div className="mt60 text-center">
              <img
                className="reservationimag2"
                src={reservation2}
                alt="secton2"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="rows res1 mt100">
        <div className="homecommondiv">
          <div className="res1data mt40">
            <div className="">
              <div className="sectionbtn"></div>
              <p className="heading text-center mt10">
                Reservations are now <br /> easier!
              </p>
            </div>
          </div>
          <div className="p50 res1">
            <img className="res1" src={res1} alt="secton2" />
          </div>
        </div>
      </div>

      <div className="rows reservation2 text-left mb100">
        <div className="homecommondiv">
          <div className="p50 reservation2div">
            <div className="pl20">
              <p className="heading">
                Get your website a reservation widget, Today!
              </p>
              <p className="subheading">
                Check out our new product, reservation widget allows a customer
                to reserve at table on a tap, no matter wherever they are which
                is available for Mobile and Website!
              </p>
              <Button className="trynowbtn" href="/buyPlan/free">
                {" "}
                TRY NOW{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="rows realast">
        <div className="homecommondiv">
          <div className="reservation3div text-right">
            <img className="reservation3" src={reservation3} alt="secton2" />
          </div>
          <div className="realast2Data">
            <p className="heading">
              Let your customer choose the available time slot!
            </p>
            <p className="subheading">
              Allows customer to choose their time based on availability of the
              <br /> the time slots and let them reserve the table!
            </p>
          </div>
        </div>
      </div>

      <div className="rows reservation4 mt60 mb100">
        <div className="homecommondiv">
          <div className="reservation4Imgdiv reservation4Imgdivtop">
            <img className="reservation4Img" src={reservation4} alt="secton2" />
          </div>
          <div className="reservation4Data text-left">
            <p className="heading">
              Get customers in a go! With Select Party Widget Feature.
            </p>
            <p className="subheading">
              Allows customer to choose their party size based on their plan
              <br /> and reserve in one tap by going on your website on laptop
              or mobile phone!{" "}
            </p>
            <Button className="trynowbtn" href="/buyPlan/free">
              {" "}
              TRY NOW{" "}
            </Button>
          </div>
          <div className="reservation4Imgdiv reservation4Imgdivbotom">
            <img
              className="reservation4Img"
              src={communitypana}
              alt="secton2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReservationData;
