import React, { Fragment,useLayoutEffect } from 'react';
import { Link } from "react-router-dom";
import './aboutus.css';
import mainCircle from "../../assets/main1.svg";
import adminapp from "../../assets/Buffer-bro.svg";
import mainLogo from "../../assets/Coffee break-amico.svg";
import curves from "../../assets/curves.svg";
import eye from "../../assets/eye.svg";
import circle1 from "../../assets/Group 38666.svg";
import circle2 from "../../assets/Group 38667.svg";
import hands from "../../assets/hands.svg";
import widget from "../../assets/Hotel Booking-amico.svg";
import leaf from "../../assets/leaf.svg";
import money from "../../assets/money.svg";
import msgUser from "../../assets/msg-user.svg";
import user from "../../assets/user.svg";
import vi from "../../assets/Visual data-pana.svg";
import waiterApp from "../../assets/Waiters-pana.svg";
import web from "../../assets/Web search-pana.svg";
import work from "../../assets/work.svg";
import ddd from "../../assets/ddd.svg";
import points from "../../assets/Group 38595.svg";
import questionnaire from "../../assets/Customer Survey-pana.svg";
import check from "../../assets/check-circle-fill.svg";


const AboutUs = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return <Fragment>
        <div className="section1">
            <div className="aboutusbg">
                <img src={mainCircle} alt="aboutus" />
            </div>
            <div className="sec1cont">
                <h6>MyTableFinder is the product of a revolutionary vision and the belief in a better tomorrow. With the aim of creating a smarter Table Management and Booking system, we at MyTableFinder understand that our restaurants and consumers deserve better.</h6>
                <div className="cards">
                    <div className="card">
                        <p>With this belief, evolves such a ticketing system that is hassle-free, efficient and simplified to provide all restaurants with an effective paper-free solution.</p>
                    </div>
                    <div className="card">
                        <p>We are here to create a world-class platform based on digital table reservation and floor planning to make your dining experiences smoother than ever before!</p>
                    </div>
                    <img src={mainLogo} alt="aboutus" />
                </div>
                <div className="points">
                    <p><span><img style={{ color: "#510AEA", marginRight: "5px" }} src={check} alt="check" /> </span> So no more discomfort of walking into a restaurant and not getting a table.</p>
                    <p><span><img style={{ color: "#510AEA", marginRight: "5px" }} src={check} alt="check" /> </span> No more chaotic paperwork to maintain several reservations.</p>
                    <p><span><img style={{ color: "#510AEA", marginRight: "5px" }} src={check} alt="check" /> </span> No more confusion about what to expect when customers enter the restaurant.</p>
                    <p><span><img style={{ color: "#510AEA", marginRight: "5px" }} src={check} alt="check" /> </span> As we present a seamless and transparent interface backed by our creative solutions to meet all your needs.</p>
                    <p><span><img style={{ color: "#510AEA", marginRight: "5px" }} src={check} alt="check" /> </span> We are damn serious when we say seamless in every aspect!</p>
                </div>
            </div>
            <div className="sec2cont">
                <h3>How does it work?</h3>
                <hr className="dash" />
                <div className="elements">
                    <div className="eleholder">
                        <div className="w33">
                            <img className="vi" src={vi} alt="visual-data" />
                        </div>

                        <div className="w33">
                            <img className="curves" src={curves} alt="curves" />
                            <div className="wcard">
                                <h1>visual interface</h1>
                                <p>MyTableFinder is automated Table management and Booking system that allows restaurants in their table reservation process by a visual interface.</p>
                            </div>
                        </div>

                    </div>
                    <div className="eleholder">
                        <div className="w33">
                            <img className="curves" src={curves} alt="curves" />
                            <div className="bcard">
                                <h1>website</h1>
                                <p>The customers can simply go on our website, search for their desired restaurant (we have suggestions as well!) and book a table by checking the availability visually. It is as simple as that.</p>
                            </div>
                        </div>
                        <div className="w33">
                            <img className="web" src={web} alt="web" />
                        </div>

                    </div>
                    <div className="eleholder">
                        <div className="w33">
                            <img className="questionnaire" src={questionnaire} alt="web" />
                        </div>
                        <div className="w33">
                            <img className="curves" src={curves} alt="curves" />
                            <div className="wcard">
                                <h1>questionnaire</h1>
                                <p>The website also allows them to keep track of customer’s preferences before they arrive with the help of a pre-visit questionnaire. This questionnaire would give an idea to both the parties of what to expect from each other.</p>
                            </div>
                        </div>
                    </div>
                    <div className="eleholder">
                        <div className="w33">
                            <img className="curves" src={curves} alt="curves" />
                            <div className="bcard">
                                <h1>offline mode</h1>
                                <p>We understand that there could be any mishap and thus, we provide an offline mode for the restaurants to keep running without any issues.</p>
                            </div>
                        </div>
                        <div className="w33">
                            <img className="ddd" src={ddd} alt="web" />
                        </div>
                    </div>
                    <img className="circle2" src={circle1} alt="circles2" />
                </div>
            </div>
            <div className="sec3cont">
                <h3>Our features</h3>
                <hr className="dash" />
                <div className="featureCardHolder">
                    <div className="featureCard">
                        <div className="featureimg">
                            <img src={waiterApp} alt="waiterapp" />
                        </div>
                        <div className="featurecont">
                            <h2>The Waiter App</h2>
                            <p>This app allows the waiter to control the overflowing crowd at the restaurant by keeping an eye on available tables, booked tables and occupied ones. This ensures them to avail enough tables to more number of customers efficiently.</p>
                            <Link to="/waiter">Learn More</Link>
                        </div>

                    </div>
                    <div className="featureCard">
                        <div className="featureimg">
                            <img src={adminapp} alt="waiterapp" />
                        </div>
                        <div className="featurecont">
                            <h2>The Admin App</h2>
                            <p>This allows proper surveillance of the employee or restaurant’s working. It makes it easier for the administrator to manage restaurant activities efficiently. Allows the admin to edit, delete or remove a waiter or co-admin to avoid conflict, manage floors and keep a track of entries and exits.</p>
                            <Link to="/adminTool">Learn More</Link>
                        </div>
                    </div>
                    <div className="featureCard">
                        <div className="featureimg">
                            <img src={widget} alt="waiterapp" />
                        </div>
                        <div className="featurecont">
                            <h2>Reservation Widget</h2>
                            <p>This app allows the waiter to control the overflowing crowd at the restaurant by keeping an eye on available tables, booked tables and occupied ones. This ensures them to avail enough tables to more number of customers efficiently.</p>
                            <Link to="/reservation">Learn More</Link>
                        </div>
                    </div>

                </div>
            </div>
            <div className="sec4cont">
                <h3>Our Mission and Values</h3>
                <hr className="dash" />
                <p className="mv">We at MyTableFinder understand how special your evening date or family dinner is. We also understand how lethargic the entire process of booking just the right table can be for both the customer and the restaurant.</p>
                <h2>Thus, our mission speaks well for our values which are :</h2>
                <div className="mvcardhold">
                    <div className="mvcard">
                        <img src={eye} alt="eye" />
                        <h5>Uncomplicated</h5>
                        <p>We at MyTableFinder believe that life is too short to be indulged in complications. Our system is based on EXACTLY that. We provide a platform that works for managing tables along with a visual interface for a better understanding.</p>
                    </div>
                    <div className="mvcard">
                        <img src={user} alt="user" />
                        <h5>User-friendly Interface</h5>
                        <p>Our website is designed in such a way that it is easier for anyone to operate and understand it. The user would not have to get into the complexities and would easily be guided through different pages and options.</p>
                    </div>
                    <div className="mvcard">
                        <img src={money} alt="money" />
                        <h5>Improving revenues</h5>
                        <p>One of the purposes of our brand is to improve the revenues of local restaurants by an improved ticketing system that helps them to take pre-payments and reservation costs easily. We understand how hard it gets for restaurants to earn enough profits, and we make sure we are there for them.</p>
                    </div>
                    <div className="mvcard">
                        <img src={leaf} alt="leaf" />
                        <h5>Environmental friendly</h5>
                        <p>With everything moving online, the website strives to reduce the overall paper-waste production of the restaurants and keep the procedure environmental friendly.</p>
                    </div>
                    <div className="mvcard">
                        <img src={work} alt="work" />
                        <h5>Reducing workload</h5>
                        <p>We know how hard it must be to run a restaurant. Knowing that we aim to reduce the workload of the employees to leave them space to catch new opportunities. The different features allow waiters and admins to work efficiently without any burden.</p>
                    </div>
                    <div className="mvcard">
                        <img src={msgUser} alt="user2" />
                        <h5>Personnel empowerment</h5>
                        <p>MyTableFinder works on the ideals of integrity and empowerment of every person. We wish to provide scopes of improvement and ensure quality in all the tasks. We strongly adhere the fair trade practices and believe in providing a list of the best.</p>
                    </div>
                    <div className="mvcard">
                        <img src={hands} alt="hands" />
                        <h5>Hospitality</h5>
                        <p>Being in the restaurant business, our ultimate aim is to provide the best experiences to the guests, be hospitable and make their visit worthwhile. This loyalty towards the customers will also create a trustworthy relationship that will make them re-visit the restaurant.</p>
                    </div>
                </div>
                <img className="circle1" src={circle2} alt="circles2" />

            </div>
            <div className="sec5cont">
                <h3>FAQ’s</h3>
                <hr className="dash" />
                <div className="faqhold">
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> How is this Table reservation system different from other similar applications?</h3>
                    <p>At MyTableFinder, we are providing the system at a very affordable cost. This way, both the restaurants and customers can save a lot of time and efforts at even lower costs. Our visual interface provides a pictorial representation of the tables available for the customers to select the one they prefer without any surprises.</p>
                
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> Does it require an internet connection?</h3>
                    <p>Yes. For live updates and changes, a minimum internet connection is required. For restaurants, there is an offline mode available as well.</p>
                
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> Can I book multiple tables at once?</h3>
                    <p>Yes. You will have an image of available tables and you can see and book as per your choice.</p>
                
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> Will I get notified when the table is booked?</h3>
                    <p>Yes. Our system works on the automated notifications method. Everything you book, a notification will be sent to both parties.</p>
                
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> What if the restaurant has no reservations?</h3>
                    <p>No reservations? Don’t worry. We have a special walk-in feature for you that allows you to track all the customers who walked into your restaurant.</p>
                
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> What if a customer has not made reservations?</h3>
                    <p>We have got that covered as well. In such a situation, the walk-in customer would be assigned a number in the queue. As soon as a table gets vacant, these customers would be allotted those by their number.</p>
                
                    <h3><span><img width="43px" height="43px" src={points} alt="points"/>&nbsp;</span> Can I book a party?</h3>
                    <p>Yes. You can book a party in advance.</p>
                
                </div>
                <br/>
                <p>We strive to provide the best services to our clients and our team is constantly available for reviewing and resolving all your queries.</p>
                <p>For further queries, contact details available at <Link to="/">www.mytablefinder.co.uk</Link></p>
            </div>
        </div>
    </Fragment>
}

export default AboutUs