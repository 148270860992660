import React,{useLayoutEffect} from "react";
import CommonInformation from "../common/CommonInformation";
import Pricing from "../common/Pricing";
import WaiterData from "./WaiterData";

function Waiter() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
      <WaiterData />
      <CommonInformation />
      {/* <Pricing /> */}
    </div>
  );
}

export default Waiter;
