import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { env } from "process";
toast.configure();

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
// const skStripe = require("stripe")(`${process.env.STRIPE_KEY}`);

const HandleStripePayment = async () => {
  const planType = sessionStorage.getItem("planType");
  const customerEmail = sessionStorage.getItem("customerEmail");
  console.log(`planType`, planType,process.env.REACT_APP_STRIPE_KEY, process.env.REACT_APP_MAIN_API_BASE_URL);
  // if (planType != "yearly" || planType != "monthly") {
  //   return;
  // }

  var activePlan;
  var planDetails = JSON.parse(sessionStorage.getItem("planDetails"));
  switch (planType) {
    case "monthly":
      var planDetails = JSON.parse(sessionStorage.getItem("planDetails"));
      activePlan = planDetails.monthPlan;
      break;
    case "yearly":
      activePlan = planDetails.yearPlan;
      break;
    default:
      break;
  }
  // await axios
  //   .get(`${process.env.REACT_APP_MAIN_API_BASE_URL}/stripe/products_list`)
  //   .then((res) => {
  //     var plans = res.data;
  //     var yearPlan;
  //     var monthPlan;
  //     console.log(`plans`, plans);
  //     plans.map((plan) => {
  //       if (plan.product_name == "Unlimited - Yearly") {
  //         yearPlan = {
  //           currency: plan.product_currency,
  //           priceId: plan.product_price_id,
  //         };
  //       }
  //       if (plan.product_name == "Unlimited - Monthly") {
  //         monthPlan = {
  //           currency: plan.product_currency,
  //           priceId: plan.product_price_id,
  //         };
  //       }
  //     });
  //     console.log(`object`, monthPlan, yearPlan);

  //     console.log(`activePlan`, activePlan);
  //     // toast.success("Successfully fetch plan", {
  //     //   position: "top-right",
  //     //   autoClose: 5000,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     // });
  //     // toast.success("Please wait...Redirect to stripe checkout page", {
  //     //   position: "top-right",
  //     //   autoClose: 5000,
  //     //   hideProgressBar: false,
  //     //   closeOnClick: true,
  //     //   pauseOnHover: true,
  //     //   draggable: true,
  //     //   progress: undefined,
  //     // });
  //   })
  // .catch((err) => {
  //   toast.error(err.response?.data.detail, {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //   });
  //   //remove this code
  // });

  // Get Stripe.js instance
  //
  // console.log("args", args);
  // const session = await stripe.checkout.sessions.create({
  //   payment_method_types: ["card"],
  //   line_items: [
  //     {
  //       price_data: {
  //         currency: "usd",
  //         product_data: {
  //           name: "T-shirt",
  //         },
  //         unit_amount: 2000,
  //       },
  //       quantity: 1,
  //     },
  //   ],
  //   mode: "payment",
  //   success_url: "https://example.com/success",
  //   cancel_url: "https://example.com/cancel",
  // });
  // console.log(`session`, session);
  // return;
  // console.log(args);
  console.log(`activePlan`, activePlan);
  const successUrl = `${window.location.protocol}//${window.location.host}/buyPlan/success`;

  const stripe = await stripePromise;
  const result = await stripe
    .redirectToCheckout({
      //   sessionId: session.id,
      mode: "subscription",

      billingAddressCollection: "auto",
      // currency: activePlan.currency,
      lineItems: [
        {
          price: activePlan?.priceId,
          quantity: 1,
        },
      ],
      customerEmail: customerEmail,
      successUrl: successUrl.replace(/ /g, "%20"),
      cancelUrl: `${
        window.location.protocol + "//" + window.location.host
      }/buyPlan/fail/`,
    })
    .then((res) => {
      console.log("success");
      sessionStorage.removeItem("planType");
      sessionStorage.removeItem("customerEmail");
      sessionStorage.removeItem("planDetails");
      return true;
    })
    .catch((err) => {
      console.log(`err`, err);
      toast.error("Error from Stripe", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // progress: undefined,
      });
    });

  if (result?.error) {
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `result.error.message`.
    toast.error(result.error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export default HandleStripePayment;
