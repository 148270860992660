import React, { useLayoutEffect } from "react";
import CommonInformation from "../common/CommonInformation";
import Pricing from "../common/Pricing";
import HomeData from "./HomeData";

function Home() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
});
  return (
    <div>
      <HomeData />
      <CommonInformation />
      {/* <Pricing /> */}
    </div>
  );
}

export default Home;
