import React from "react";
import "./waiter.css";
import waiter1 from "../../assets/waiter1.png";
import waiter2 from "../../assets/waiter2.png";
import waiter3 from "../../assets/waiter3.png";
import waiter4 from "../../assets/waiter4.png";
import waiter5 from "../../assets/waiter5.png";
import waiter1top from "../../assets/waiter1top.png";
import waiter2top from "../../assets/waiter2top.png";
import { Button } from "react-bootstrap";

function WaiterData() {
  return (
    <div className="m50">
      <div className="waitersection1">
        <div className="sectionbtn"></div>
        <div className="mt25">
          Do more with our
          <br /> Waiter App!
        </div>
      </div>

       <div className="rows waitersection2">
            <div className="homecommondiv">
              <div className="waitersection2Imgdiv waitersection2top">
                <img className="waitersection2Img" src={waiter1top} alt="secton2" />
              </div>  
              <div className="waitersection2Imgdiv waitersection2botom">
                <img className="waitersection2Img" src={waiter1} alt="secton2" />
              </div>  
              <div className="waitersection2Data">
                    <img className="waitersection2botom" src={waiter2} alt="secton2" />
                    <p className="waitersection21">
                      Now, Worry less about managing
                      <br />
                      huge crowd at your Restaurant!
                    </p>
                    <div className="waitersection22">
                      We mainly focus on crowd management and leaving a smooth flow of
                      <br />
                      business at your restaurant day to day.
                    </div>
              </div>
          </div>
          </div>

      <div className="rows waitersection3 mt60 justify-content">
        <div className="homecommondiv">
            <div className="waitersection3rightdiv waitersection3top">
                <img className="waitersection3right" src={waiter2top} alt="waiter4" />
            </div>

              <div className="waitersection3left mt30">
                <div className="waitersection33">
                  <div className="waitersection31">We take care of non-reserves!</div>
                  <div className="waitersection32">
                    Allowing a waiter to add a walk-in straight in device provided and
                    continue the regular restaurant practices.
                  </div>
                </div>
                <div>
                  <img className="waiter3 waitersection3botom" src={waiter3} alt="waiter3" />
                </div>
              </div>
              <div className="waitersection3rightdiv waitersection3botom">
                <img className="waitersection3right" src={waiter4} alt="waiter4" />
              </div>
        </div>
      </div>

      <div className="rows waitersection4 mt60 justify-content">
        <div className="homecommondiv">
            <div className="waiter4div">
              <img className="waiter4" src={waiter5} alt="waiter5" />
            </div>
            <div className="waitersection43">
              <div className="waitersection41">
                Worry less about table
                <br /> being vacant, let us do
                <br /> this for you!
              </div>
              <div className="waitersection42">
                Now, table will be automatically assigned once the table is vacant
                <br /> by the current occupancy
              </div>
              <Button className="primarys" href="/price">
                Purchase{" "}
              </Button>
            </div>
        </div> 
      </div>
    </div>
  );
}

export default WaiterData;
